/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 93번째 로그에서는 MS 실적 발표와 반독점 청문회, 버블 500, 표준 꺼져?, 요즘 사용하는 앱에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/1768796"
  }, "스탠다드아웃 | 오디오 천국 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=nlP7rkmtsZ4"
  }, "신과함깨 코로나19 이후 폭발! 20조 중고거래시장, 당근마켓의 심상치 않는 독주 - 빅사이즈#7")), "\n"), "\n", React.createElement(_components.h3, null, "Git 2.28 릴리즈"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2020-07-27-highlights-from-git-2-28/"
  }, "Highlights from Git 2.28 - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/questions/698313/what-is-trunk-branch-and-tag-in-subversion"
  }, "svn - What is trunk, branch and tag in Subversion? - Stack Overflow")), "\n"), "\n", React.createElement(_components.h3, null, "MS 실적 발표와 반독점 청문회"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2020/07/23/2020072300785.html"
  }, "코로나 사태에도 마이크로소프트 2분기 매출 13% 증가 - Chosunbiz")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2020/07/31/2020073100679.html"
  }, "애플·아마존·페이스북 美 빅테크, 2분기 깜짝실적... 아이폰 신작 출시 10월로 연기 - Chosunbiz")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.donga.com/news/Inter/article/all/20200729/102214982/1"
  }, "아마존·애플·구글·페이스북 CEO, 反독점 청문회 증언 ‘주목’")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Microsoft_Corp._v._United_States"
  }, "Microsoft Corp. v. United States - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=YeLBQCpCi9c&list=PLLjqqQ3pwbHiGs-qJ6-cSsdG4zg8n7I_j"
  }, "Bill Gates - Deposition Part 1 of 12 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Sherman_Antitrust_Act_of_1890"
  }, "Sherman Antitrust Act of 1890 - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Standard_Oil_Co._of_New_Jersey_v._United_States"
  }, "Standard Oil Co. of New Jersey v. United States - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2020/07/22/slack-has-filed-an-antitrust-complaint-against-microsoft-teams-in-the-eu/"
  }, "Slack has filed an antitrust complaint over Microsoft Teams in the EU | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Breakup_of_the_Bell_System"
  }, "Breakup of the Bell System - Wikipedia")), "\n"), "\n", React.createElement(_components.h3, null, "버블500"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Breakup_of_the_Bell_System"
  }, "Breakup of the Bell System - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Price%E2%80%93sales_ratio"
  }, "Price–sales ratio - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nikolamotor.com/"
  }, "Nikola Corp")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Nikola_Tesla"
  }, "Nikola Tesla - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.edaily.co.kr/news/read?newsId=01364486625804672&mediaCodeNo=257"
  }, "이정훈의 마켓워치 <12> 매출 제로 니콜라 기적 만든 스팩(SPAC)")), "\n"), "\n", React.createElement(_components.h3, null, "IT 붐과 신입 교육"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Coding_bootcamp"
  }, "Coding bootcamp - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.vim.org/"
  }, "welcome home : vim online")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gnu.org/software/emacs/"
  }, "GNU Emacs - GNU Project")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://missing-semester-kr.github.io/"
  }, "여러분의 CS 교육에서 누락된 학기 · the missing semester of your cs education")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Design_Patterns"
  }, "Design Patterns - Wikipedia")), "\n"), "\n", React.createElement(_components.h3, null, "스탠다드아웃 제목 - 표준 꺼져?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%8A%A4%ED%85%8C%EC%9D%B8%EB%A6%AC%EC%8A%A4%EA%B0%95"
  }, "스테인리스강 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Standard_streams"
  }, "Standard streams - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/"
  }, "Stack Overflow - Where Developers Learn, Share, & Build Careers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://easylaw.go.kr/CSP/CnpClsMain.laf?popMenu=ov&csmSeq=902&ccfNo=3&cciNo=1&cnpClsNo=1"
  }, "유한책임회사 설립·운영 > 유한책임회사의 운영 > 업무집행 및 대표권 > 업무집행자 (본문) | 찾기쉬운 생활법령정보")), "\n"), "\n", React.createElement(_components.h3, null, "요즘 사용하는 앱 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://asana.com/"
  }, "Manage Your Team’s Work, Projects, & Tasks Online · Asana")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://monday.com/pm-visual-t/aw-todo"
  }, "To Do List App | monday.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.atlassian.com/software/jira/pricing"
  }, "Jira Pricing - Monthly and Annual Subscription Cost per User")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/wkpark/moniwiki"
  }, "wkpark/moniwiki: MoniWiki")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://graphviz.org/"
  }, "Graphviz - Graph Visualization Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Private Markdown Notes for iPhone, iPad and Mac | Bear App")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/alpha/"
  }, "Alpha Page | Bear's Editor Future Evolutions | Bear App")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
